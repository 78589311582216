
body {
  background-color: #272727;
  color: white;
  font-family: monospace;
}

a {
  color: white;
}

button {
  color: white;
  background-color: transparent;
  border: 0;
  margin: 1em 0px;
  padding: 0;
  font-family: monospace;
  cursor: pointer;
}

.App {
  display: block;
  font-family: monospace;
  white-space: pre;
  margin: 1em 0px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
